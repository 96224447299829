import React from "react";
import "./Greeting.css";
import { Tooltip } from "react-bootstrap";
// import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import { Fade } from "react-awesome-reveal";
import BannerImg from "./BannerImg";
import Typewriter from "typewriter-effect";
import CompetitiveSites from "../../components/competitiveSites/CompetitiveSites";
import { competitiveSites } from "../../portfolio";
export default function Greeting(props) {
  const theme = props.theme;
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <div>
                <Tooltip><strong>Greeting in india</strong></Tooltip>
                <h1 className="greeting-text" style={{ color: theme.orange }}>
                  {greeting.title}
                </h1>

              </div>

              <h1 className="greeting-sub" style={{ color: theme.grayText }}>
                I'm {greeting.sub}
              </h1>
              <h1 className="greeting-typewriter" style={{ color: theme.blue }}>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Engineering Student")
                      .pauseFor(200)
                      .deleteAll()
                      .typeString("Data Science (Python & R)")
                      .pauseFor(200)
                      .deleteAll()
                      .typeString("Web Developer (MERN)")
                      .pauseFor(200)
                      .deleteAll()
                      .start()
                  }}
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>
              <span
                className="greeting-text-span subTitle"
                style={{ color: theme.text }}>
                <div className="entities">

                  An Engineering Student {" "}
                  Pursuing a Degree in Artificial Intelligence and Data Science . 
                  Interested in
                  Collabs and {" "}

                  Data Science and Web Development Roles.
                </div>
              </span>
              <CompetitiveSites logos={competitiveSites.competitiveSites} />
              <div className="button-greeting-div">
                <Button text="Contact me" href="/contact" />
                <Button
                  text="See my resume"
                  newTab={true}
                  href={greeting.resumeLink}
                />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <BannerImg />
          </div>
        </div>
      </div>
    </Fade>
  );
}
