export const blackTheme = {
    body: "#000000",
    text: "#ffffff",
    blue: "#1179f7",
    orange: "#ffa500",
  };
  
  export const lightTheme = {
    body: "#ffffff",
    text: "#000000",
    blue: "#1179f7",
    orange: "#ffa500",
  };
  
  export const chosenTheme = blackTheme;