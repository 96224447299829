const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Rohit Verma | Portfolio",
  description:
    "A Data Science practitioner who thrives to leverage startups with AI based solutions with a blend of UI/UX",
  og: {
    title: "Rohit Verma Portfolio",
    type: "website",
    url: "",
  },
};

//Home Page
const greeting = {
  title: "Namaste!",
  sub: "Rohit Verma",
  logo_name: "Rohit Verma",
  resumeLink: "https://drive.google.com/open?id=10ptTpg4Xezxw1GOGQFxifvqe9AUx14Pu&authuser=rohitpawanverma9%40gmail.com&usp=drive_fs",
};

// home logos
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Github",
      iconifyClassname: "simple-icons:github",
      style: {
        color: "white",
      },
      profileLink: "https://github.com/kumar9625",
    },
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#ffa116",
      },
      profileLink: "https://leetcode.com/rohitpawanverma9/",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/rohitverma9625",
    },
    {
      siteName: "Medium",
      iconifyClassname: "simple-icons:medium",
      style: {
        color: "white",
      },
      profileLink: "https://medium.com/@rohitverma9625",
    },
    {
      siteName: "LinkedIn",
      iconifyClassname: "simple-icons:linkedin",
      style: {
        color: "#0B67C2",
      },
      profileLink: "https://www.linkedin.com/in/rohitverma9625/",
    },

  ],
};

//what i do?
const skills = {
  data: [
    {

      title: "Web Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building Responsive Full Stack Web Apps Using MongoDB,ExpressJs ReactJS & NodeJS",
        "⚡ Utilized MongoDb ATLAS to work with cloud databases for deploying apps.",
        "⚡ Having experience in Domain & Hosting using  Heroku.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "BootStrap",
          fontAwesomeClassname: "logos-bootstrap",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Data Science",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Created a number of data science analyses, including a Netflix analysis",
        "⚡ Have Understanding of all the intuitions and mathematics related to data science.",
        "⚡ Having solid hands-on experience with Pandas, MatplotLib, and Scikit-Learn for Data Cleaning, Processing, and Analysis.",
        "⚡ Familiar with both Python and R because I have used both.",
        "⚡ Created a number of data science analyses, including a Netflix analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "NumPy",
          fontAwesomeClassname: "logos-numpy",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Tableau",
          fontAwesomeClassname: "logos-tableau",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },

  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I have Created  Various Web Apps Majorly in MERN Stack And Deployed on Heroku.",
};

//project cards
// colors:[primary,secondary,success,danger,warning,info,light,dark,]
const projectcards = {
  list: [
    {
      title: "SocioNodes",
      img_path: "socionodes.gif",
      description:
        "A Social Network Based web App ",
      tags: [
        
        {
          lang: "MongoDB",
          color: "#004782",
        },
        {
          lang: "Express",
          color: "#820032",
        },
        {
          lang: "Nodejs",
          color: "#820032",
        },
        {
          lang: "NPM",
          color: "#820032",
        },
        
        {
          lang: "Heroku",
          color: "#3c0095",
        },
      ],
      link: "https://socionodes.onrender.com/ ",
      code: "https://github.com/kumar9625/SocioNodes",
      linkcolor: "white",
    },

    
    {
      title: "Any Commerce",
      img_path: "anycommerce.gif",
      description:
        "An E-Commerce App build using Pexels , Faker API.",
      tags: [
        
        {
          lang: "React",
          color: "#ff4b4b",
        },
        {
          lang: "CSS",
          color: "#ff4b4b",
        },
        {
          lang: "Bootstrap",
          color: "#ff4b4b",
        },
        {
          lang: "Nodejs",
          color: "#ff4b4b",
        },
      ],
      link: "https://any-commerce.vercel.app/",
      code: "https://github.com/kumar9625/AnyCommerce",
      linkcolor: "white",
    },
    {
      title: "Tic-Tac-Toe Game",
      img_path: "tictactoe.gif",
      description:
        "Basic Tic-Tac-Toe Game Build Using React!",
      tags: [
        
        {
          lang: "React",
          color: "#ff4b4b",
        },
        {
          lang: "CSS",
          color: "#ff4b4b",
        },
        {
          lang: "Bootstrap",
          color: "#ff4b4b",
        },
        {
          lang: "Nodejs",
          color: "#ff4b4b",
        },
      ],
      link: "https://tic-tac-toe-react-app-puce.vercel.app/",
      code: "https://github.com/kumar9625/Tic-Tac-Toe-react-app",
      linkcolor: "white",
    },
    {
      title: "NetFlix Data Analysis",
      img_path: "netflix.gif",
      description:
        "A Detailed DATA ANALYSIS ON THE NETFLIX TV SHOWS AND MOVIES!",
      tags: [
        
        
        {
          lang: "Jupyter",
          color: "#ff4b4b",
        },
        {
          lang: "Pandas",
          color: "#ff4b4b",
        },
        {
          lang: "Numpy",
          color: "#ff4b4b",
        },
        {
          lang: "MatplotLib",
          color: "#ff4b4b",
        },
      ],
      link: "https://www.kaggle.com/code/rohitverma9625/netflix-data-analysis",
      code: "https://www.kaggle.com/code/rohitverma9625/netflix-data-analysis",
      linkcolor: "white",
    },
    {
      title: "University Faculty Record",
      img_path: "faculty.png",
      description:
        "A Web Portal for university faculty to manage their research papers, certificates or projects at one place!",
      tags: [
        
        
        {
          lang: "Nodejs",
          color: "#ff4b4b",
        },
        {
          lang: "Express",
          color: "#ff4b4b",
        },
        {
          lang: "MongoDB",
          color: "#ff4b4b",
        },
        
      ],
      link: "https://college-faculty-record.onrender.com/",
      code: "https://github.com/kumar9625/piet_faculty_program",
      linkcolor: "white",
    },
    {
      title: "Parkvision",
      img_path: "parkvision.gif",
      description:
        "A Machine Learning Project for detectin available parking slots in real time.",
      tags: [
        
        
        {
          lang: "OpenCV",
          color: "#ff4b4b",
        },
        {
          lang: "Pytorch",
          color: "#ff4b4b",
        },
        {
          lang: "Python",
          color: "#ff4b4b",
        },
        
      ],
      link: "https://github.com/kumar9625/Parkvision-opencv-",
      code: "https://github.com/kumar9625/Parkvision-opencv-",
      linkcolor: "white",
    },
    
  ],
};

const resumeleft = {
  left: {
    title: ["Achievements"],
    data: [
      {
        title: "D2C Chief Igniter",
        subtitle: "Unstop Private Limited",
        date: "June 2021 - present",
        content: [
          "First One to Build D2C Club in My Collge.",
          "Selected As Chief Igniter Via Interview And Application Phase.",
          "Organised Several Hackathons And Events.",
          "Remained Chief Igniter for two Consecutive Years.",
        ],
      },
      {
        title: "Technical Content Writer ",
        subtitle: "Medium",
        date: "June 2022 - Present",
        content: [
          "Write Technical Content On Data Science, Web Develpoment.",
          
        ],
      },
      {
        title: "IIT BOMBAY CAMPUS AMBASSADOR",
        subtitle: "IIT BOMBAY",
        date: "June 2020 - Present",
        content: [
          "Slected As IIT  BOmbay Campus Ambassador in 2022.",
          "Completed Tasks Provided By E-cell",
          "Got Opportunities To Attend Enterpreneurship Events",
        ],
      },
      
    ],
  },
};

const resumeright = {
  right: [
    {
      title: ["Education"],
      data: [
        {
          title: "Bachelor of Technology in Computer Science",
          subtitle: " Panipat Institute Of Engineering And Technology",
          date: "2020 - 2024",
          content: ["Specialization in Artificial Intelligence And Data Science"],
        },
        {
          title: "Jain Bharati Mrigavati Vidyalya",
          subtitle: "New Delhi",
          content: ["Class XII CBSE"],
        },
      ],
    },
  ],
}

//certificate cards
const certifications = {
  certifications: [
  
    
    {
      title: "Python",
      subtitle: "HackerRank",
      logo_path: "python-01.png",
      certificate_link: "https://www.hackerrank.com/rohitpawanverma9",
      alt_name: "HackerRank",
      color_code: "#000000",
    },
    {
      title: "C++",
      subtitle: "HackerRank",
      logo_path: "cpp.png",
      certificate_link: "https://www.hackerrank.com/rohitpawanverma9",
      alt_name: "HackerRank",
      color_code: "#000000",
    },
    
    {
      title: "Python Bootcamp Zero To Hero",
      subtitle: "Udemy",
      logo_path: "udemy-01.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-b51bc8f3-8950-4f2d-88ba-7bf4e2f1104c/",
      alt_name: "Udemy",
      color_code: "#000000",
    },
    {
      title: "C Programming-Build Bank ATM Machine",
      subtitle: "Udemy",
      logo_path: "udemy-01.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-b51bc8f3-8950-4f2d-88ba-7bf4e2f1104c/",
      alt_name: "Udemy",
      color_code: "#000000",
    },
    {
      title: "ReactJs Complete Developer Bootcamp",
      subtitle: "Learn Code Online",
      logo_path: "reactjs.png",
      certificate_link:
        "courses.learncodeonline.in/learn/certificate/5926791-53635",
      alt_name: "LCO",
      color_code: "#000000",
    },
    
    
    {
      title: "Mathematics For Machine Learning",
      subtitle: "Imperial College Of London",
      logo_path: "coursera-01.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/N7ZHJGZUERF2",
      alt_name: "Stanford University",
      color_code: "#000000",
    },
    {
      title: "Data Scientist's ToolBox",
      subtitle: "John Hopkins University",
      logo_path: "coursera-01.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5E8LT78M33PX",
      alt_name: "John Hopkins University",
      color_code: "#000000",
    },
    {
      title: "R Programming",
      subtitle: "John Hopkins University",
      logo_path: "coursera-01.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/H94BS338PZAH",
      alt_name: "John Hopkins University",
      color_code: "#000000",
    },
    
    
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "contact.jpg",
    description:
      "Find me on all these platforms.",
  },
  addressSection: {
    subtitle: "New Delhi, India",
  },
  phoneSection: {
    subtitle: "+91 9625123027",
  },
};

//contact icons
const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/kumar9625",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "white", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/rohitverma9625/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },

  {
    name: "Gmail",
    link: "mailto:rohitpawanverma9@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/kumarrohit9625/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#F2047D", // Reference https://simpleicons.org/?q=instagram
  },
  {
    name: "Whatsapp",
    link: "https://wa.me/919625123027?text=I%20got%20here%20via%20your%20portfolio%20website",
    fontAwesomeIcon: "fa-whatsapp", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#4ACB5A", // Reference https://simpleicons.org/?q=instagram
  },
];

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  projectsHeader,
  contactPageData,
  projectcards,
  certifications,
  resumeleft,
  resumeright,
};
